//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Menu from '../Menu'
import footer from '../../../common/mixins/footer'
import auth from '../../../common/mixins/auth'
import alertWeb from '../../../common/mixins/alertWeb'
import redirectWeb from '../../../common/mixins/redirectWeb'

export default {
  name: 'Footer',
  components: {
    Menu
  },
  mixins: [footer, auth, alertWeb, redirectWeb],
  data () {
    return {
      footerModalShow: false
    }
  },
  beforeCreate () {
    if (process.client) {
      this.$store.dispatch('getInfoPages')
    }
  },
  methods: {
    hideModalAndLogout () {
      this.footerModalShow = false
      this.logoutUser()
    }
  }
}
